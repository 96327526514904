import React from "react"
import { useTranslation } from "next-i18next"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import FeaturedIcons from "@components/FeaturedIcons"
import Container from "@components/v2/Container"
import { IconsProps } from "@components/v3/Icons/Icons"
import Space from "@layouts/Space"

const StyledContainer = tw(Container)`
    flex xl:flex-row flex-col 
    justify-around
    xl:py-10 py-8
    xl:gap-12 gap-4
  
`

const WrapperItem = tw(Space)`!gap-0`

const Description = tw.span`
    paragraph-3 text-main dark:text-dark-main
    text-center
`
const Surplus = () => {
    const { t } = useTranslation("home")
    const isDesktop = useMediaQuery({ minWidth: 1280 })

    const actionLists = [
        {
            key: 1,
            icon: "SwitchVertical",
            label: t("us_stock.surplus.switch.label"),
            desc: t("us_stock.surplus.switch.desc")
        },

        {
            key: 2,
            icon: "LineChartDown",
            label: t("us_stock.surplus.line_chart.label"),
            desc: t("us_stock.surplus.line_chart.desc")
        },
        {
            key: 3,
            icon: "Safe",
            label: t("us_stock.surplus.safe.label"),
            desc: t("us_stock.surplus.safe.desc")
        }
    ]
    return (
        <StyledContainer>
            {actionLists.map(({ key, icon, label, desc }) => (
                <WrapperItem key={key} direction='vertical' align='center'>
                    <FeaturedIcons
                        icon={icon as IconsProps["icon"]}
                        size={isDesktop ? "xl" : "lg"}
                        className='xl:!gap-4'
                        variant='purple'
                        labelSize={isDesktop ? 2 : 3}
                        label={label}
                        labelColor='bold'
                        labelPosition='bottom'
                    />
                    <Description>{desc}</Description>
                </WrapperItem>
            ))}
        </StyledContainer>
    )
}

export default Surplus
